import React, { useEffect, useState } from 'react'

import UserNavbar from '../../components/userNavbar/UserNavbar'
import './createCase.css'
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createCaseApi, fetchCaseDetailApi, updateCaseApi } from '../../services/apis/caseApi';
import { authToken } from '../../services/token';
import { useDispatch, useSelector } from 'react-redux';
import { recievedError, requestSent, responseRecieved } from '../../redux/slices/utilsSlice';
import NavigationButtons from '../../components/navigationButtons/NavigationButtons';
import { USER_ROLES, ENVS } from '../../utils/constant';

const CreateCase = () => {

    const loading = useSelector(state => state.utilsObj.loading)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const caseRequestId = searchParams.get('case_request_id');
    const {REACT_APP_FRONTEND_IMG_PATH} = ENVS;
    const today = new Date().toISOString().split('T')[0];

    const [caseData, setCaseData] = useState({
        name: '',
        brokerName: '',
        employerName: '',
        addressArray: [
            {
                address: '',
                addressType: 'current',
                moveInDate: '',
                moveOutDate: ''
            }
        ]
    });

    async function fetchCaseDetail() {

        try {
            const token = authToken();

            const response = await fetchCaseDetailApi(caseRequestId, token);

            if (response.status === 200) {
                let structuredData = {
                    name: response.data.data.name,
                    brokerName: response.data.data.broker_name,
                    employerName: response.data.data.employer_name,
                    addressArray: response.data.data.CaseAddresses.map((val) => ({
                        address: val.address,
                        addressType: val.address_type,
                        moveInDate: val.move_in_date,
                        moveOutDate: val.move_out_date
                    }))
                };

                setCaseData(structuredData)
            }

        } catch (error) {

            console.log('error=>', error);

            if (error.response.status === 400 || error.response.status === 500) {
                return toast.error(error.response.data.error);
            } else if (error.response.status === 403) {
                return navigate('/login')
            }
            return toast.error('Something went wrong');
        }
    }

    useEffect(() => {

        if (caseRequestId) {
            fetchCaseDetail()
        }
    }, [])


    const handleAddAddress = () => {
        //this function is used to replicate address div
        if (caseData.addressArray.length >= 3) {
            return toast.error('You can only add upto 3 addresses only')
        }

        setCaseData(prevState => ({
            ...prevState,
            addressArray: [...prevState.addressArray, {
                address: '',
                addressType: 'current',
                moveInDate: '',
                moveOutDate: ''
            }]
        }));
    }

    const handleAddressChange = (index, field, value) => {
        setCaseData(prevState => ({
            ...prevState,
            addressArray: prevState.addressArray.map((address, i) => i === index ? { ...address, [field]: value } : address)
        }));
    }

    const handleRemoveAddress = (index) => {
        setCaseData(prevState => ({
            ...prevState,
            addressArray: prevState.addressArray.filter((_, i) => i !== index)
        }));
    }



    const handleCaseSubmit = async (e) => {

        e.preventDefault();

        try {

            let data = caseData;
            data.addressArray.map(value => {
                if (value.addressType === 'current' && value.moveOutDate === "") {
                    value.moveOutDate = null;
                }
                return value;
            });

            if (!data.brokerName || data.brokerName.trim() === '') delete data.brokerName
            if (!data.employerName || data.employerName.trim() === '') delete data.employerName

            const token = authToken();

            dispatch(requestSent());

            let response;
            if (!caseRequestId) {
                //if case request id is not present then create case 
                response = await createCaseApi(data, token);
            } else {
                //else update the case
                response = await updateCaseApi(data, caseRequestId, token)
            }

            if (response.status === 200) {
                // toast.success(response.data.message);
                dispatch(responseRecieved());
                navigate(`/docs-upload?case_request_id=${response.data.data.id}`);
                return;
            }

        } catch (error) {

            console.log('error=>', error);
            dispatch(recievedError());

            if (error.response.status === 400 || error.response.status === 500) {
                return toast.error(error.response.data.error);
            } else if (error.response.status === 403) {
                return navigate('/login')
            }
            return toast.error('Something went wrong');
        }
    }

    return (
        <>
            <UserNavbar />
            <div className='common-container-2'>
                {/* <div>
                    <button className='case-request-btn'>Case Requests</button>
                </div> */}
                <NavigationButtons buttons={[{ label: 'Case Requests', isActive: true }]} />
            </div>

            <div className="create-case-container">
                <div className='create-new-case-header'>
                    <div><img src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/back-arrow.png`} alt="backarrow" className='back-arrow' style={{ cursor: 'pointer' }} onClick={() => navigate(-1)} /></div>
                    <div><h2>Create new case</h2></div>
                </div>
                <div className="case-form-container">
                    <form onSubmit={handleCaseSubmit}>
                        <div className='broad-input-div'>
                            <label htmlFor="name">Client Name<span className='error-red'> *</span></label>
                            <input type="text" name='name' placeholder='Enter Name' className='broad-input' value={caseData.name} onChange={e => setCaseData({ ...caseData, name: e.target.value })} required='true' />
                        </div>
                        {
                            localStorage.getItem('role') === USER_ROLES.BROKER && (
                                <div className='broad-input-div'>
                                    <label htmlFor="brokerName">Broker's Name</label>
                                    <input type="text" name='brokerName' placeholder='Enter Broker Name' className='broad-input' value={caseData.brokerName} onChange={e => setCaseData({ ...caseData, brokerName: e.target.value })} />
                                </div>
                            )
                        }
                        <div className='broad-input-div'>
                            <label htmlFor="employerName">Employer's Name<span className='error-red'> *</span></label>
                            <input type="text" name='employerName' placeholder='Enter Employer Name' className='broad-input' value={caseData.employerName} onChange={e => setCaseData({ ...caseData, employerName: e.target.value })} required='true' />
                        </div>
                        <div className="address-entry-parent">
                            {caseData.addressArray.map((address, index) => (
                                <div key={index} className="address-entry-container">
                                    <div className='broad-input-div plus-icon-parent'>
                                        <label htmlFor="address">Client Address<span className='error-red'> *</span></label>
                                        <textarea placeholder="Enter Your Address" rows={6} className='text-area-case' value={address.address} onChange={e => handleAddressChange(index, 'address', e.target.value)} required='true'></textarea>
                                        {index === caseData.addressArray.length - 1 && (
                                            <>
                                                <div className="add-more">
                                                    <span className="plus-icon-child" onClick={handleAddAddress}>+</span>
                                                </div>
                                                {
                                                    index > 0 && (
                                                        <div className="substract">
                                                            <span className="minus-icon-child" onClick={() => handleRemoveAddress(index)}>-</span>
                                                        </div>
                                                    )
                                                }

                                            </>
                                        )}

                                    </div>
                                    <div className="flex-feilds">
                                        <div>
                                            <label htmlFor="address-type">Address Type*</label>
                                            <select className='select-box-style' value={address.addressType} onChange={e => handleAddressChange(index, 'addressType', e.target.value)}>
                                                <option value="previous">Previous</option>
                                                <option value="current">Current</option>
                                            </select>
                                        </div>
                                        <div className="date-container">
                                            <div className="move-in-div">
                                                <label htmlFor="moveInDate">Move-In Date</label>
                                                <input type="date" max={today} name='moveInDate' value={address.moveInDate} onChange={e => handleAddressChange(index, 'moveInDate', e.target.value)} />
                                            </div>
                                            <div className="move-out-div">
                                                <label htmlFor="moveOutDate">Move-Out Date</label>
                                                <input disabled={address.addressType === 'current'} type="date" name='moveOutDate' value={address.moveOutDate} onChange={e => handleAddressChange(index, 'moveOutDate', e.target.value)} />
                                            </div>
                                        </div>
                                        {
                                            index === caseData.addressArray.length - 1 && (
                                                <div className='save-case-btn-container'>
                                                    <button type='submit' className={!loading ? 'save-case-btn' : 'save-case-btn-disabled'} disabled={loading ? true : false}>Save</button>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            ))}

                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default CreateCase