import React from 'react'

const DataSection = ({ title, data, noDataMessage, color }) => {
    return (
        <div
            className={`custom-mb ${title === 'Gambling websites' ? 'scroll-y-container' : ''}`}
        >
            {data?.length > 0 ? (
                <>
                    <h4 className="custom-mb" style={{ color: `${title === 'Gambling websites' ? 'red' : 'black'}` }}>{title}</h4>
                    {data?.map((val, index) => (
                        <div key={index}>
                            <p className="custom-mb"><span className='card-span'>Date:</span>{val?.date}</p>
                            {
                                val?.payment_method && (
                                    <p className="custom-mb"><span className='card-span'>Payment method:</span>
                                        {
                                            title === 'Matched Net Pay from Payslip' ?
                                                <span className={val?.paymentMethodStatus?.includes('Mismatch') ? 'none-match-text' : 'full-match-text'}>
                                                    {val.payment_method}
                                                </span>
                                                :
                                                <span>{val.payment_method}</span>
                                        }
                                    </p>
                                )
                            }
                            <p className="custom-mb"><span className='card-span'>Description:</span>{val?.description}</p>
                            <p className="custom-mb"><span className='card-span'>Paid Out:</span>{val?.paid_out || 0}</p>
                            <p className="custom-mb"><span className='card-span'>Paid In:</span>{val?.paid_in || 0}</p>
                            {
                                val?.bank_statement_no && <p className="custom-mb"><span className='card-span'>Bank Statement Number:</span>{val?.bank_statement_no || 0}</p>
                            }
                            {
                                val?.paymentMethodStatus &&
                                <p className="custom-mb">
                                    <span className='card-span'>Pay method:</span>
                                    <span className={val?.paymentMethodStatus?.includes('Mismatch') ? 'none-match-text' : 'full-match-text'}>
                                        {val?.paymentMethodStatus}
                                    </span>
                                </p>
                            }
                            <br />
                        </div>
                    ))}
                </>
            ) : (
                <span className='card-span' style={{ color }}>{noDataMessage}</span>
            )}
        </div>
    );
};


export default DataSection