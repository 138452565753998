import React from "react";
import { generateFileName } from "../.././utils/helper"

const StatementUpload = ({ statements, titlePrefix, fileType, isDynamicFileType, REACT_APP_FRONTEND_IMG_PATH, fileUploadStatus, getFileName, removeFile, handleFileChange }) => {
  return (
    <div className="inp-files-container">
      {statements.map((statement, index) => (
        <div className="bankstatement-container" key={index}>
          <div
            className={`${fileUploadStatus[statement] === "Pending"
              ? "pending"
              : "pending uploaded"
              }`}
          >
            <img
              src={
                fileUploadStatus[statement] === "Pending"
                  ? `${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/time.png`
                  : `${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/checkmark.png`
              }
              alt="status"
            />
            <p className="document-name text-overflow-elipsis">
              {getFileName(fileUploadStatus[statement])}&nbsp;
            </p>
            {fileUploadStatus[statement] !== "Pending" && (
              <img
                width="15"
                className="cursor-pointer"
                src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/remove.png`}
                alt="remove-file"
                onClick={() =>
                  removeFile(statement, fileUploadStatus[statement])
                }
              />
            )}
          </div>
          <div className="doc-icon">
            <img
              src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/images/docicon.png`}
              alt="file-inp"
              className="cloud-img"
            />
            <p style={{ fontSize: "12px" }}>
              {generateFileName(titlePrefix, index + 1)}
            </p>
            <div className="file-icon-btn">
              <img
                src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/document-text.png`}
                alt="file-inp"
              />
              <span>
                {isDynamicFileType && index <= 2 ? "Upload pdf" : fileType}
              </span>
            </div>
          </div>
          <input
            type="file"
            name={statement}
            onChange={handleFileChange}
            className="upload-pdf-inp"
            accept={
              isDynamicFileType && index <= 2
                ? ".pdf"
                : fileType === "Upload pdf"
                  ? ".pdf"
                  : ".csv"
            }
          />
        </div>
      ))}
    </div>
  );
};

export default StatementUpload;