import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./docsUpload.css";
import UserNavbar from "../../components/userNavbar/UserNavbar";
import { authTokenFormData } from "../../services/token";
import { uploadDocumentsApi } from "../../services/apis/caseApi";
import {
  recievedError,
  requestSent,
  responseRecieved,
} from "../../redux/slices/utilsSlice";
import NavigationButtons from "../../components/navigationButtons/NavigationButtons";
import { ENVS } from "../../utils/constant";
import { fetchCaseDetailApi } from "../../services/apis/caseApi";
import FileUploadSection from "../../components/fileUploadSection/FileUploadSection";
import StatementUpload from "../../components/fileUploadSection/StatementUpload";
import { generateFileName } from "../../utils/helper";

const DocsUpload = () => {
  const navigate = useNavigate();
  const loading = useSelector((state) => state.utilsObj.loading);
  const [caseDetail, setCaseDetail] = useState(null); // State to store the fetched case detail
  const [removeDocsPath, setRemoveDocsPath] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const caseRequestId = searchParams.get("case_request_id");
  const isEdit = searchParams.get("isEdit") || false;
  const { REACT_APP_FRONTEND_IMG_PATH } = ENVS;
  const [fileData, setFileData] = useState({
    payslip_1: null,
    payslip_2: null,
    payslip_3: null,
    bank_statement_1: null,
    bank_statement_2: null,
    bank_statement_3: null,
    credit_card_statement_1: null,
    credit_card_statement_2: null,
    credit_card_statement_3: null,
    joint_account_statement_1: null,
    joint_account_statement_2: null,
    joint_account_statement_3: null,
    p60: null,
    employment_contract: null,
    extra_payslips: [],
    tax_summary_1: null,
    tax_summary_2: null,
    tax_summary_3: null,
    tax_calculation_1: null,
    tax_calculation_2: null,
    tax_calculation_3: null,
    company_account_1: null,
    company_account_2: null,
    company_account_3: null,
  });

  const [fileUploadStatus, setFileUploadStatus] = useState({
    payslip_1: "Pending",
    payslip_2: "Pending",
    payslip_3: "Pending",
    bank_statement_1: "Pending",
    bank_statement_2: "Pending",
    bank_statement_3: "Pending",
    credit_card_statement_1: "Pending",
    credit_card_statement_2: "Pending",
    credit_card_statement_3: "Pending",
    joint_account_statement_1: "Pending",
    joint_account_statement_2: "Pending",
    joint_account_statement_3: "Pending",
    p60: "Pending",
    employment_contract: "Pending",
    extra_payslips: [],
    tax_summary_1: "Pending",
    tax_summary_2: "Pending",
    tax_summary_3: "Pending",
    tax_calculation_1: "Pending",
    tax_calculation_2: "Pending",
    tax_calculation_3: "Pending",
    company_account_1: "Pending",
    company_account_2: "Pending",
    company_account_3: "Pending",
  });

  const [payDuration, setPayDuration] = useState('monthly');
  // Update extra payslips based on payDuration
  useEffect(() => {
    const extraPayslipsCount =
      payDuration === 'weekly' ? 1 :
        payDuration === 'four-weekly' ? 10 :
          0;

    if (isEdit && caseDetail?.extra_payslips?.length > 0) {
      // In edit mode, preserve existing uploads while adjusting array size
      const newArray = Array(extraPayslipsCount).fill(null).map((_, index) => {
        // If we have an existing upload at this index, preserve it
        return caseDetail.extra_payslips[index] || null;
      });

      const newStatusArray = Array(extraPayslipsCount).fill("Pending").map((_, index) => {
        // If we have an existing upload at this index, preserve its status
        return caseDetail.extra_payslips[index]
          ? `Uploaded ${caseDetail.extra_payslips[index].doc_name}`
          : "Pending";
      });

      setFileData(prev => ({
        ...prev,
        extra_payslips: newArray
      }));

      setFileUploadStatus(prev => ({
        ...prev,
        extra_payslips: newStatusArray
      }));
    }
    else {
      // In create mode or when no existing payslips, just set new arrays
      setFileData(prev => ({
        ...prev,
        extra_payslips: Array(extraPayslipsCount).fill(null)
      }));

      setFileUploadStatus(prev => ({
        ...prev,
        extra_payslips: Array(extraPayslipsCount).fill("Pending")
      }));
    }
  }, [payDuration]);

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files[0]) {
      const fileName = files[0].name;

      // Check for duplicate files
      const isDuplicate = Object.entries(fileUploadStatus).some(([key, value]) => {
        if (key === 'extra_payslips') {
          return value.some(status => status.split("Uploaded ")[1]?.trim() === fileName);
        }
        return value.split("Uploaded ")[1]?.trim() === fileName;
      });

      if (isDuplicate) {
        // alert("Same document cannot be uploaded twice");
        toast.error("Same document cannot be uploaded twice");
        return;
      }

      // Handle extra payslips
      if (name.startsWith('extra_payslip_')) {
        const index = parseInt(name.split('_')[2]) - 1;
        setFileData(prev => {
          const newExtraPayslips = [...prev.extra_payslips];
          newExtraPayslips[index] = files[0];
          return { ...prev, extra_payslips: newExtraPayslips };
        });

        setFileUploadStatus(prev => {
          const newExtraPayslips = [...prev.extra_payslips];
          newExtraPayslips[index] = `Uploaded ${fileName}`;
          return { ...prev, extra_payslips: newExtraPayslips };
        });
      } else {
        console.log('inrefular')
        // Handle regular files
        setFileData(prev => ({
          ...prev,
          [name]: files[0],
        }));

        setFileUploadStatus(prev => ({
          ...prev,
          [name]: `Uploaded ${fileName}`,
        }));
      }
    }
  };



  // check is path is a local path or server url
  const isServerUrl = (path) => {
    console.log('path', path)
    const splitedPath = path.split(" ")[1];
    console.log('splitedPath', splitedPath)
    const serverUrl = splitedPath?.startsWith("/") ?? false;

    console.log(splitedPath, serverUrl);

    return { splitedPath, serverUrl };
  };

  // remove file
  // const removeFile = (name, path, isBankStatement = false, index) => {
  //   // console.log('saare', name, path, isBankStatement, index)
  //   const data = isServerUrl(path);
  //   console.log('data', data)
  //   setFileData((prevState) => ({
  //     ...prevState,
  //     [name]: null,
  //   }));

  //   setFileUploadStatus((prevState) => ({
  //     ...prevState,
  //     [name]: `Pending`,
  //   }));

  //   if (data.serverUrl) {
  //     // const splitedPath = data.splitedPath;

  //     setRemoveDocsPath((prevState) => [
  //       ...prevState,
  //       name
  //     ]);

  //     // if (isBankStatement) {
  //     //   setRemoveDocsPath((prevState) => [
  //     //     ...prevState,
  //     //     caseDetail[`bank_statement_${index + 1}`]
  //     //   ]);
  //     // }
  //   }
  // };

  const removeFile = (name, path, isBankStatement = false, index) => {
    // Handle extra payslips
    if (name.startsWith('extra_payslip_')) {
      const payslipIndex = parseInt(name.split('_')[2]) - 1;

      // Update fileData
      setFileData(prevState => {
        const newExtraPayslips = [...prevState.extra_payslips];
        newExtraPayslips[payslipIndex] = null;
        return {
          ...prevState,
          extra_payslips: newExtraPayslips
        };
      });

      // Update fileUploadStatus
      setFileUploadStatus(prevState => {
        const newExtraPayslips = [...prevState.extra_payslips];
        newExtraPayslips[payslipIndex] = "Pending";
        return {
          ...prevState,
          extra_payslips: newExtraPayslips
        };
      });

      // If in edit mode and the file was from server, add to removeDocsPath
      if (isEdit && caseDetail?.extra_payslips?.[payslipIndex]) {
        setRemoveDocsPath(prevState => [
          // console.log(caseDetail.extra_payslips[payslipIndex].id),
          ...prevState,
          `extra_payslip_${caseDetail.extra_payslips[payslipIndex].id}`
        ]);

        // Update caseDetail
        setCaseDetail(prevState => ({
          ...prevState,
          extra_payslips: prevState.extra_payslips.filter((_, idx) => idx !== payslipIndex)
        }));
      }
    } else {
      // Handle regular payslips and bank statements (existing logic)
      const data = isServerUrl(path);

      setFileData((prevState) => ({
        ...prevState,
        [name]: null,
      }));

      setFileUploadStatus((prevState) => ({
        ...prevState,
        [name]: "Pending",
      }));

      if (data.serverUrl) {
        setRemoveDocsPath((prevState) => [
          ...prevState,
          name
        ]);
      }
    }
  };

  // remove extra server trailing path and return actual file name
  const getFileName = (filename) => {
    const segments = filename.split("/");
    return segments[segments.length - 1];
  };

  const handleDocsSubmit = async (e) => {
    e.preventDefault();
    for (const [key, value] of Object.entries(fileData)) {
      if (!value) {
        delete fileData[key];
      }
    }

    try {
      const token = authTokenFormData();
      const formData = new FormData();

      // payslips
      [fileData.payslip_1, fileData.payslip_2, fileData.payslip_3].map(
        (file, index) => {
          if (file) {
            formData.append(`payslip_${index + 1}`, file);
          }
        }
      );

      // bank statements
      [
        fileData.bank_statement_1,
        fileData.bank_statement_2,
        fileData.bank_statement_3,
      ].map((file, index) => {
        if (file) {
          formData.append(`bank_statement_${index + 1}`, file);
        }
      });

      // credit card statements
      [
        fileData.credit_card_statement_1,
        fileData.credit_card_statement_2,
        fileData.credit_card_statement_3,
      ].map((file, index) => {
        if (file) {
          formData.append(`credit_card_statement_${index + 1}`, file);
        }
      });

      // joint account statements
      [
        fileData.joint_account_statement_1,
        fileData.joint_account_statement_2,
        fileData.joint_account_statement_3,
      ].map((file, index) => {
        if (file) {
          formData.append(`joint_account_statement_${index + 1}`, file);
        }
      });

      if (fileData.p60) {
        formData.append(`p60`, fileData.p60)
      }

      if (fileData.employment_contract) {
        formData.append(`employment_contract`, fileData.employment_contract)
      }

      if (fileData.extra_payslips.length > 0) {
        fileData.extra_payslips.forEach((file, index) => {
          if (file) {
            formData.append(`extra_payslips`, file);
          }
        });
      }

      //Tax Summary
      [
        fileData.tax_summary_1,
        fileData.tax_summary_2,
        fileData.tax_summary_3,
      ].map((file, index) => {
        if (file) {
          formData.append(`tax_summary_${index + 1}`, file);
        }
      });

      [
        fileData.tax_calculation_1,
        fileData.tax_calculation_2,
        fileData.tax_calculation_3
      ].map((file, index) => {
        if (file) {
          formData.append(`tax_calculation_${index + 1}`, file);
        }
      });

   
      [
        fileData.company_account_1,
        fileData.company_account_2,
        fileData.company_account_3,
      ].map((file, index) => {
        if (file) {
          formData.append(`company_account_${index + 1}`, file);
        }
      });

      
      if (removeDocsPath.length > 0) {
        console.log('removeDocsPath', removeDocsPath)
        removeDocsPath.forEach((path) => {
          formData.append('remove_docs_path', path);
        });
      }

      dispatch(requestSent());
      const response = await uploadDocumentsApi(formData, caseRequestId, token);
      if (response.status === 200) {
        dispatch(responseRecieved());
        toast.success(response.data.message);
        if (isEdit && isEdit === 'true') {
          navigate(`/report?case_request_id=${caseRequestId}&isEdit=${isEdit}`);
        } else {
          console.log('isEditFalse')
          navigate(`/report?case_request_id=${caseRequestId}`);
        }
        return;
      }
    } catch (error) {
      console.log("error=>", error);
      dispatch(recievedError());
      if (error.response.status === 400 || error.response.status === 500) {
        return toast.error(error.response.data.error);
      } else if (error.response.status === 403) {
        return navigate("/login");
      }
      return toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    if (isEdit) {
      fetchCaseDetail();
    }
  }, []);



  const handlePayDurationChange = (event) => {
    console.log('event.target.value', event.target.value)
    setPayDuration(event.target.value);
  }

  const updateFileUploadStatus = (data) => {
    // for payslips
    [data.payslip_1, data.payslip_2, data.payslip_3].map((fileName, index) => {
      if (fileName) {
        setFileUploadStatus((prevState) => ({
          ...prevState,
          [`payslip_${index + 1}`]: `Uploaded ${fileName}`,
        }));
      }
    });

    // for bank statements
    [data.bank_statement_4, data.bank_statement_5, data.bank_statement_6].map(
      (fileName, index) => {
        if (fileName) {
          setFileUploadStatus((prevState) => ({
            ...prevState,
            [`bank_statement_${index + 1}`]: `Uploaded ${fileName}`,
          }));
        }
      }
    );

    // for credit card statements
    [
      data.credit_card_statement_1,
      data.credit_card_statement_2,
      data.credit_card_statement_3,
    ].map((fileName, index) => {
      if (fileName) {
        setFileUploadStatus((prevState) => ({
          ...prevState,
          [`credit_card_statement_${index + 1}`]: `Uploaded ${fileName}`,
        }));
      }
    });

    // for joint account statements
    [
      data.joint_account_statement_1,
      data.joint_account_statement_2,
      data.joint_account_statement_3,
    ].map((fileName, index) => {
      if (fileName) {
        setFileUploadStatus((prevState) => ({
          ...prevState,
          [`joint_account_statement_${index + 1}`]: `Uploaded ${fileName}`,
        }));
      }
    });

    //p60
    if (data.p60) {
      setFileUploadStatus((prevState) => ({
        ...prevState,
        p60: `Uploaded ${data.p60}`,
      }));
    }

    //employment_contract
    if (data.employment_contract) {
      setFileUploadStatus((prevState) => ({
        ...prevState,
        employment_contract: `Uploaded ${data.employment_contract}`,
      }));
    }

    [
      data.tax_summary_1,
      data.tax_summary_2,
      data.tax_summary_3,
    ].map((fileName, index) => {
      if (fileName) {
        setFileUploadStatus((prevState) => ({
          ...prevState,
          [`tax_summary_${index + 1}`]: `Uploaded ${fileName}`,
        }));
      }
    });

    [
      data.tax_calculation_1,
      data.tax_calculation_2,
      data.tax_calculation_3,
    ].map((fileName, index) => {
      if (fileName) {
        setFileUploadStatus((prevState) => ({
          ...prevState,
          [`tax_calculation_${index + 1}`]: `Uploaded ${fileName}`,
        }));
      }
    });

    [
      data.company_account_1,
      data.company_account_2,
      data.company_account_3
    ].map((fileName, index) => {
      if (fileName) {
        setFileUploadStatus((prevState) => ({
          ...prevState,
          [`company_account_${index + 1}`]: `Uploaded ${fileName}`
        }))
      }
    })

    // Handle extra payslips from CaseExtraDocs
    if (data.CaseExtraDocs?.length > 0) {
      const extraPayslips = data.CaseExtraDocs.filter(
        doc => doc.doc_name === "extra_payslip"
      );

      if (extraPayslips.length > 0) {
        // Update payDuration based on number of extra payslips
        const newPayDuration =
          extraPayslips.length === 1 ? 'weekly' :
            extraPayslips.length > 1 ? 'four-weekly' :
              'monthly';

        setPayDuration(newPayDuration);

        // Update caseDetail with extra payslips
        setCaseDetail(prevState => ({
          ...prevState,
          extra_payslips: extraPayslips.map(doc => ({
            id: doc.id,
            doc_path: doc.doc_path,
            doc_name: doc.doc_name,
            doc_type: doc.doc_type
          }))
        }));


        // Update extra payslips status
        setFileUploadStatus(prevState => ({
          ...prevState,
          extra_payslips: extraPayslips.map(doc => {
            return `Uploaded ${doc.doc_name}`; // Make sure to return the string
          })
        }));


        // Update fileData for extra payslips
        setFileData(prevState => ({
          ...prevState,
          extra_payslips: Array(extraPayslips.length).fill(null)
        }));
      }
    }

  };



  const fetchCaseDetail = async () => {
    try {
      const token = authTokenFormData();
      dispatch(requestSent());
      const response = await fetchCaseDetailApi(caseRequestId, token);
      if (response.status === 200) {
        const data = response.data.data;
        console.log('data', data)
        setCaseDetail(data);
        // update file upload status
        updateFileUploadStatus(data);
        dispatch(responseRecieved());
      }
    } catch (error) {
      console.log("error=>", error);
      dispatch(recievedError(error));
    }
  };

  const renderFileUploadBox = (name, label, index = null, payslip) => {
    const isExtraPayslip = name.startsWith('extra_payslip_');
    const status = isExtraPayslip
      ? fileUploadStatus.extra_payslips[index]
      : fileUploadStatus[name];

    return (
      <div className="bankstatement-container" key={index}>
        <div
          className={`${status === "Pending"
            ? "pending"
            : "pending uploaded"
            }`}
        >
          <img
            src={
              status === "Pending"
                ? `${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/time.png`
                : `${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/checkmark.png`
            }
            alt="status"
          />
          <p className="document-name text-overflow-elipsis">
            {getFileName(status)}&nbsp;
          </p>
          {status !== "Pending" ? (
            <img
              width="15"
              className="cursor-pointer"
              src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/remove.png`}
              alt="remove-file"
              onClick={() =>
                removeFile(name, payslip.doc_path, false, index)
              }
            />
          ) : (
            ""
          )}
        </div>
        <div className="doc-icon">
          <img
            src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/images/docicon.png`}
            alt="file-inp"
            className="cloud-img"
          />
          <p style={{ fontSize: "12px" }}>Payslip {index + 4}</p>
          <div className="file-icon-btn">
            <img
              src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/document-text.png`}
              alt="file-inp"
            />
            <span>Upload pdf</span>
          </div>
        </div>
        <input
          type="file"
          name={name}
          onChange={handleFileChange}
          className="upload-pdf-inp"
          accept=".pdf"
          required={
            index === 0 && status === "Pending"
          }
        />
      </div>
    );
  };


  return (
    <>
      <UserNavbar />
      <div className="common-container-2">
        <NavigationButtons
          buttons={[
            { label: "Case Requests", isActive: false },
            { label: "Documents Upload", isActive: true },
          ]}
        />
      </div>

      <div className="create-case-container">
        <div className="create-new-case-header">
          <div>
            <img
              src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/back-arrow.png`}
              alt="backarrow"
              className="back-arrow"
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigate(`/create-case?case_request_id=${caseRequestId}`)
              }
            />
          </div>
          <div>
            <h2>Upload Documents</h2>
          </div>
        </div>
        <p className="upload-doc-one-liner" style={{ display: 'flex', justifyContent: 'space-between' }}>
          To confirm, please upload the following documents
          {/* JUST UNCOMMENT THIS TO START PROCESSING 13 PAYSLIPS AGAIN */}
          {/* <select name="payDuration" value={payDuration} onChange={handlePayDurationChange}>
            <option value="monthly">Monthly</option>
            <option value="weekly">Weekly</option>
            <option value="four-weekly">Four Weekly</option>
          </select> */}
        </p>

        <form
          method="post"
          onSubmit={handleDocsSubmit}
          encType="multipart/form-data"
        >
          <div className="inp-files-container-parent">
            <>
              <div className="inp-files-container">
                {["payslip_1", "payslip_2", "payslip_3"].map(
                  (payslip, index) => (
                    <div className="bankstatement-container" key={index}>
                      <div
                        className={`${fileUploadStatus[payslip] === "Pending"
                          ? "pending"
                          : "pending uploaded"
                          }`}
                      >
                        <img
                          src={
                            fileUploadStatus[payslip] === "Pending"
                              ? `${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/time.png`
                              : `${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/checkmark.png`
                          }
                          alt="status"
                        />
                        <p className="document-name text-overflow-elipsis">
                          {getFileName(fileUploadStatus[payslip])}&nbsp;
                        </p>
                        {fileUploadStatus[payslip] !== "Pending" ? (
                          <img
                            width="15"
                            className="cursor-pointer"
                            src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/remove.png`}
                            alt="remove-file"
                            onClick={() =>
                              removeFile(payslip, fileUploadStatus[payslip])
                            }
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="doc-icon">
                        <img
                          src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/images/docicon.png`}
                          alt="file-inp"
                          className="cloud-img"
                        />
                        <p style={{ fontSize: "12px" }}>{generateFileName('Payslip', index + 1)}</p>
                        <div className="file-icon-btn">
                          <img
                            src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/document-text.png`}
                            alt="file-inp"
                          />
                          <span>Upload pdf</span>
                        </div>
                      </div>
                      <input
                        type="file"
                        name={payslip}
                        onChange={handleFileChange}
                        className="upload-pdf-inp"
                        accept=".pdf"
                        required={
                          index === 0 && fileUploadStatus[payslip] === "Pending"
                        }
                      />
                    </div>
                  )
                )}
              </div>


              {fileData.extra_payslips.length > 0 && (
                <>
                  <div className="inp-files-container">
                    {fileData.extra_payslips.map((payslip, idx) => (
                      renderFileUploadBox(
                        `extra_payslip_${idx + 1}`,
                        `Extra Payslip ${idx + 1}`,
                        idx,
                        payslip // Passing the whole value of each payslip
                      )
                    ))}
                  </div>
                </>
              )}



              <div className="inp-files-container">
                {[
                  "bank_statement_1",
                  "bank_statement_2",
                  "bank_statement_3",
                ].map((bankStatement, index) => (
                  <div className="bankstatement-container" key={index}>
                    <p
                      className={`${fileUploadStatus[bankStatement] === "Pending"
                        ? "pending"
                        : "pending uploaded"
                        }`}
                    >
                      <img
                        src={
                          fileUploadStatus[bankStatement] === "Pending"
                            ? `${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/time.png`
                            : `${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/checkmark.png`
                        }
                        alt="status"
                      />
                      <p className="document-name text-overflow-elipsis">
                        {getFileName(fileUploadStatus[bankStatement])}&nbsp;
                      </p>
                      {fileUploadStatus[bankStatement] !== "Pending" ? (
                        <img
                          width="15"
                          className="cursor-pointer"
                          src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/remove.png`}
                          alt="remove-file"
                          onClick={() =>
                            removeFile(
                              bankStatement,
                              fileUploadStatus[bankStatement],
                              true,
                              index
                            )
                          }
                        />
                      ) : (
                        ""
                      )}
                    </p>

                    <div className="doc-icon">
                      <img
                        src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/images/docicon.png`}
                        alt="file-inp"
                        className="cloud-img"
                      />
                      <p style={{ fontSize: "12px" }}>
                        {/* Bank Statement {index + 1} */}
                        {generateFileName('Bank Statement', index + 1)}
                      </p>
                      <div className="file-icon-btn">
                        <img
                          src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/document-text.png`}
                          alt="file-inp"
                        />
                        {index === 0 || index === 1 || index === 2 ? (
                          /** Fist three containers will be for csvs of bank statement */
                          <span>Upload pdf</span>
                        ) : (
                          /** Last three containers will be for pdfs of bank statement */
                          <span>Upload csv</span>
                        )}
                      </div>
                    </div>
                    <input
                      type="file"
                      name={bankStatement}
                      onChange={handleFileChange}
                      className="upload-pdf-inp"
                      accept={
                        index === 0 || index === 1 || index === 2
                          ? ".pdf"
                          : ".csv"
                      }
                      required={
                        index === 0 &&
                        fileUploadStatus[bankStatement] === "Pending"
                      }
                    />
                  </div>
                ))}
              </div>


              <StatementUpload
                statements={[
                  "credit_card_statement_1",
                  "credit_card_statement_2",
                  "credit_card_statement_3",
                ]}
                titlePrefix="Credit Card Statement"
                fileType="Upload pdf"
                isDynamicFileType={true}
                REACT_APP_FRONTEND_IMG_PATH={REACT_APP_FRONTEND_IMG_PATH}
                fileUploadStatus={fileUploadStatus}
                getFileName={getFileName}
                removeFile={removeFile}
                handleFileChange={handleFileChange}
              />

              <StatementUpload
                statements={[
                  "joint_account_statement_1",
                  "joint_account_statement_2",
                  "joint_account_statement_3",
                ]}
                titlePrefix="Joint Acc Statement"
                fileType="Upload csv"
                isDynamicFileType={true}
                REACT_APP_FRONTEND_IMG_PATH={REACT_APP_FRONTEND_IMG_PATH}
                fileUploadStatus={fileUploadStatus}
                getFileName={getFileName}
                removeFile={removeFile}
                handleFileChange={handleFileChange}
              />

              <FileUploadSection
                files={["tax_summary_1", "tax_summary_2", "tax_summary_3"]}
                fileUploadStatus={fileUploadStatus}
                handleFileChange={handleFileChange}
                removeFile={removeFile}
                sectionTitle="Tax Overview"
                REACT_APP_FRONTEND_IMG_PATH={REACT_APP_FRONTEND_IMG_PATH}
                getFileName={getFileName}
              />

              
              <FileUploadSection
                files={["tax_calculation_1", "tax_calculation_2", "tax_calculation_3"]}
                fileUploadStatus={fileUploadStatus}
                handleFileChange={handleFileChange}
                removeFile={removeFile}
                sectionTitle="Tax Calculation"
                REACT_APP_FRONTEND_IMG_PATH={REACT_APP_FRONTEND_IMG_PATH}
                getFileName={getFileName}
              />

              <FileUploadSection
                files={["company_account_1", "company_account_2", "company_account_3"]}
                fileUploadStatus={fileUploadStatus}
                handleFileChange={handleFileChange}
                removeFile={removeFile}
                sectionTitle="Company account"
                REACT_APP_FRONTEND_IMG_PATH={REACT_APP_FRONTEND_IMG_PATH}
                getFileName={getFileName}
              />

              <div className="inp-files-container">
                {/** P60 */}
                <div className="bankstatement-container">
                  <p
                    className={`${fileUploadStatus.p60 === "Pending"
                      ? "pending"
                      : "pending uploaded"
                      }`}
                  >
                    <img
                      src={
                        fileUploadStatus.p60 === "Pending"
                          ? `${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/time.png`
                          : `${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/checkmark.png`
                      }
                      alt="status"
                    />
                    <p className="document-name text-overflow-elipsis">
                      {getFileName(fileUploadStatus.p60)}
                      &nbsp;
                    </p>
                    {fileUploadStatus.p60 !== "Pending" ? (
                      <img
                        width="15"
                        className="cursor-pointer"
                        src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/remove.png`}
                        alt="remove-file"
                        onClick={() =>
                          removeFile(
                            'p60',
                            fileUploadStatus.p60
                          )
                        }
                      />
                    ) : (
                      ""
                    )}
                  </p>

                  <div className="doc-icon">
                    <img
                      src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/images/docicon.png`}
                      alt="file-inp"
                      className="cloud-img"
                    />
                    <p style={{ fontSize: "12px" }}>
                      P60
                    </p>
                    <div className="file-icon-btn">
                      <img
                        src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/document-text.png`}
                        alt="file-inp"
                      />
                      <span>Upload pdf</span>
                    </div>
                  </div>
                  <input
                    type="file"
                    name="p60"
                    onChange={handleFileChange}
                    className="upload-pdf-inp"
                    accept=".pdf"
                  />
                </div>
                {/**P60 */}

                {/**Employment Contract */}
                <div className="bankstatement-container">
                  <p
                    className={`${fileUploadStatus.employment_contract === "Pending"
                      ? "pending"
                      : "pending uploaded"
                      }`}
                  >
                    <img
                      src={
                        fileUploadStatus.employment_contract === "Pending"
                          ? `${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/time.png`
                          : `${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/checkmark.png`
                      }
                      alt="status"
                    />
                    <p className="document-name text-overflow-elipsis">
                      {getFileName(fileUploadStatus.employment_contract)}
                      &nbsp;
                    </p>
                    {fileUploadStatus.employment_contract !== "Pending" ? (
                      <img
                        width="15"
                        className="cursor-pointer"
                        src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/remove.png`}
                        alt="remove-file"
                        onClick={() =>
                          removeFile(
                            'employment_contract',
                            fileUploadStatus.employment_contract
                          )
                        }
                      />
                    ) : (
                      ""
                    )}
                  </p>

                  <div className="doc-icon">
                    <img
                      src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/images/docicon.png`}
                      alt="file-inp"
                      className="cloud-img"
                    />
                    <p style={{ fontSize: "12px" }}>
                      Emp. Contract
                    </p>
                    <div className="file-icon-btn">
                      <img
                        src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/document-text.png`}
                        alt="file-inp"
                      />
                      <span>Upload pdf</span>
                    </div>
                  </div>
                  <input
                    type="file"
                    name="employment_contract"
                    onChange={handleFileChange}
                    className="upload-pdf-inp"
                    accept=".pdf"
                  />
                </div>
                {/**Employment Contract */}
              </div>


            </>
            {loading ? (
              <>
                <div className="cs-dot-loader"></div>
                <h3 className="wait-msg">Please wait we are extracting the uploaded files.</h3>
              </>
            ) : (
              <button
                className="save-case-btn"
                style={{ display: "block", margin: "0 auto" }}
                disabled={loading}
              >
                Confirm & continue
              </button>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default DocsUpload;
