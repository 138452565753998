import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import UserNavbar from '../../components/userNavbar/UserNavbar';
import './staff.css';
import { fetchStaffApi } from '../../services/apis/brokerApi';
import { authToken } from '../../services/token';
import { recievedError, requestSent, responseRecieved } from '../../redux/slices/utilsSlice';
import FilterBox from '../../components/filterBox/FilterBox';
import { USER_STATUS } from '../../utils/constant';
import AddCaseModal from '../../components/addCaseModal/AddCaseModal';
import { openAddCaseModal } from '../../redux/slices/addCaseModalSlice';
import { toast } from 'react-toastify';
import { ENVS } from '../../utils/constant';
import Loader from '../../components/loader/Loader';

const Staff = () => {

  const navigate = useNavigate();
  const token = authToken();
  const dispatch = useDispatch();
  const [staffData, setStaffData] = useState([]);
  const isAddCaseModalOpen = useSelector(state => state.addCaseModalObj.isOpen);
  const loading = useSelector((state) => state.utilsObj.loading);

  const [paginationData, setPaginationData] = useState({
    pageNo: 1,
    limit: 10,
    totalPages: 0
  });

  const [filterData, setFilterData] = useState({
    username: "",
    email: "",
    status: ""
  });

  const {REACT_APP_FRONTEND_IMG_PATH, REACT_APP_REQUEST_URL} = ENVS

  useEffect(() => {
    fetchStaff()
  }, [paginationData.pageNo]);


  const fetchStaff = async (req, res) => {

    try {

      dispatch(requestSent());
      const response = await fetchStaffApi(paginationData.pageNo, filterData, token);
      if (response.status === 200) {

        setStaffData(response.data.data.rows);

        setPaginationData({
          pageNo: response.data.pageNo,
          limit: response.data.perPage,
          totalPages: response.data.totalPages
        });

        dispatch(responseRecieved())
      }

    } catch (error) {

      console.log('error=>', error);
      dispatch(recievedError(error));
      if (error.response.status === 400 || error.response.status === 500) {
        return toast.error(error.response.data.error);
      } else if (error.response.status === 403) {
        return navigate('/login')
      }
      return toast.error('Something went wrong');
    }
  }

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilterData(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleSearchSubmit = () => {
    fetchStaff()
  }

  const handlePageClick = (data) => {
    const updatedPageNo = data.selected + 1; // Pagination starts from 0, so adding 1
    setPaginationData({ ...paginationData, pageNo: updatedPageNo });
  };


  return (

    
      loading ? 
      <Loader/> : (
        <>
          {isAddCaseModalOpen && <AddCaseModal fetchStaff={fetchStaff} />}
          <UserNavbar />
          <div className="common-page">
    
            <div className='account-box-header'>
              <div className='flex-div'>
                <img src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/back-arrow.png`} alt="lessthan" style={{ marginRight: '16px', cursor: 'pointer' }} onClick={() => navigate(-1)} /><h1>Staff</h1>
              </div>
              <div className='staff-head-btns'>
                <button onClick={() => navigate('/create-staff')}>Add Staff Member <img src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/plus.png`} alt="add-staff" className='staff-head-btn-img' /></button>
                <button
                  onClick={() => navigate('/staff-history')}
                >Staff History <img src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/blueclock.png`} alt="wait-staff" className='staff-head-btn-img' /></button>
              </div>
            </div>
    
    
            <FilterBox filterData={filterData} handleFilterChange={handleFilterChange} handleSearchSubmit={handleSearchSubmit} />
    
            <div className="table-container">
              {
                staffData.length === 0 ? (
                  <div className="no-data">
                    <h1>No Staff Found</h1>
                  </div>
                ) : (
                  <table className="transaction-table">
                    <thead>
                      <tr className='table-row'>
                        <th className='table-head'>S.No.</th>
                        <th className='table-head'>Staff</th>
                        <th className='table-head'>Email</th>
                        <th className='table-head'>Contact</th>
                        <th className='table-head'>Address</th>
                        <th className='table-head'>Case Remaining</th>
                        <th className='table-head'>Status</th>
                        <th className='table-head'>Image</th>
                        <th className="table-head">Date Created</th>
                        <th className="table-head"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        staffData.map((val, index) => {
                          return (
                            <tr className='table-row' key={index}>
                              <td className='table-data'>{index + 1 + (paginationData.pageNo - 1) * paginationData.limit}</td>
                              <td className='table-data auto-horizontal-overflow'>{val?.username}</td>
                              <td className='table-data auto-horizontal-overflow'>{val?.email}</td>
                              <td className='table-data auto-horizontal-overflow'>{val?.phone}</td>
                              <td className='table-data auto-horizontal-overflow'>{val?.address}</td>
                              <td className='table-data auto-horizontal-overflow'>{val?.case_remaining}</td>
                              <td className='table-data auto-horizontal-overflow'><span className={`${val.status === USER_STATUS.ACTIVE ? 'custom-badge-success' : val.status === USER_STATUS.BLOCKED ? 'custom-badge-secondary' : 'custom-badge-error'}`}>{val?.status}</span></td>
                              <td className='table-data auto-horizontal-overflow'>
                                <img src={`${REACT_APP_REQUEST_URL}${val?.profile_pic}`} alt="profile" className='staff-profile-picture' />
                              </td>
                              <td className='table-data auto-horizontal-overflow'>{moment(val?.createdAt).format('llll')}</td>
                              <td className="table-data auto-horizontal-overflow" style={{ minWidth: '160px' }}>
                                <div className='staff-action-container'>
                                  {val.status !== USER_STATUS.DELETED && (
                                    <span
                                      className='text-btn'
                                      onClick={() => navigate(`/update-staff?staff_id=${val?.id}`)}
                                    >
                                      Edit
                                    </span>
                                  )}
                                  {val.status === USER_STATUS.ACTIVE && (
                                    <span
                                      className='text-btn'
                                      onClick={() => dispatch(openAddCaseModal(val))}
                                    >
                                      Add Cases
                                    </span>
                                  )}
                                </div>
                              </td>
    
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                )
              }
    
              {
                staffData.length > 0 &&
                <ReactPaginate
                  pageCount={paginationData.totalPages}
                  pageRangeDisplayed={2}
                  marginPagesDisplayed={3}
                  forcePage={paginationData.pageNo - 1 }
                  onPageChange={handlePageClick}
                  containerClassName={'pagination'}
                  activeClassName={'active'}
                />
              }
            </div>
          </div>
        </>
      )
    
  )
}

export default Staff