import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import UserNavbar from '../../components/userNavbar/UserNavbar';
import { authToken } from '../../services/token';
import { recievedError, requestSent, responseRecieved } from '../../redux/slices/utilsSlice';
import './commonCases.css';
import { fetchStaffCasesApi } from '../../services/apis/brokerApi';
import Loader from '../../components/loader/Loader';
import { ENVS } from '../../utils/constant';

const BrokerTeamCasesHistory = () => {

  const navigate = useNavigate();
  const token = authToken();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.utilsObj.loading);

  const [staffCasesHistoryData, setStaffCaseHistoryData] = useState([]);

  const [paginationData, setPaginationData] = useState({
    pageNo: 1,
    limit: 10,
    totalPages: 0
  });

  const { REACT_APP_FRONTEND_IMG_PATH, REACT_APP_REQUEST_URL } = ENVS;

  useEffect(() => {
    fetchStaffCases();
  }, [paginationData.pageNo]);


  const fetchStaffCases = async (req, res) => {
    try {

      dispatch(requestSent())
      const response = await fetchStaffCasesApi(paginationData.pageNo, token);
      console.log('response', response)
      if (response.status === 200) {
        setStaffCaseHistoryData(response.data.data.rows);
        setPaginationData({
          pageNo: response.data.pageNo,
          limit: response.data.perPage,
          totalPages: response.data.totalPages
        });
        dispatch(responseRecieved())
      }


    } catch (error) {

      console.log('error=>', error);
      dispatch(recievedError(error));

    }
  }

  const handlePageClick = (data) => {
    const updatedPageNo = data.selected + 1; // Pagination starts from 0, so adding 1
    setPaginationData({ ...paginationData, pageNo: updatedPageNo });
  };


  return (

    loading ? <Loader /> : (
      <>
        <UserNavbar />

        <div className="common-page">
          <div className='account-box-header'>
            <div className='flex-div'>
              <img src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/back-arrow.png`} alt="lessthan" style={{ marginRight: '16px', cursor: 'pointer' }} onClick={() => navigate(-1)} /><h1>Staff Cases History</h1>
            </div>
          </div>

          <div className="table-container table-horizontal-overflow">
            {
              staffCasesHistoryData.length === 0 ? (
                <div className="no-data">
                  <h1>No case history found</h1>
                </div>
              ) : (
                <table className="transaction-table">
                  <thead>
                    <tr className='table-row'>
                      <th className='table-head'>S.No.</th>
                      <th className='table-head'>Name</th>
                      {/* <th className='table-head'>Broker Name</th> */}
                      <th className='table-head'>1st Payslip</th>
                      <th className='table-head'>2nd Payslip</th>
                      <th className='table-head'>3rd Payslip</th>
                      <th className='table-head'>1st Bank Statement</th>
                      <th className='table-head'>2nd Bank Statement</th>
                      <th className='table-head'>3rd Bank Statement</th>
                      <th className='table-head'>1st Credit Card Statement</th>
                      <th className='table-head'>2nd Credit Card Statement</th>
                      <th className='table-head'>3rd Credit Card Statement</th>
                      <th className='table-head'>1st Joint Account Statement</th>
                      <th className='table-head'>2nd Joint Account Statement</th>
                      <th className='table-head'>3rd Joint Account Statement</th>
                      <th className='table-head'>P60</th>
                      <th className='table-head'>Employment Contract</th>
                      <th className='table-head'>1st Tax year overview</th>
                      <th className='table-head'>2nd Tax year overview</th>
                      <th className='table-head'>3rd Tax year overview</th>
                      <th className='table-head'>1st Tax year calculation</th>
                      <th className='table-head'>2nd Tax year calculation</th>
                      <th className='table-head'>3rd Tax year calculation</th>
                      <th className='table-head'>1st Company Account</th>
                      <th className='table-head'>2nd Company Account</th>
                      <th className='table-head'>3rd Company Account</th>
                      <th className='table-head'>Case Status</th>
                      <th className='table-head'>Score</th>
                      <th className='table-head'>Staff Name</th>
                      <th className="table-head"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      staffCasesHistoryData.map((val, index) => {
                        return (
                          <tr className='table-row' key={index}>
                            <td className='table-data'>{index + 1 + (paginationData.pageNo - 1) * paginationData.limit}</td>
                            <td className='table-data'>{val?.name}</td>
                            {/* <td className='table-data'>{val?.broker_name}</td> */}
                            <td className="table-data">
                              <span
                                className={`view-texts ${!val?.payslip_1 ? 'view-texts-disabled' : ''}`}
                                onClick={!val?.payslip_1 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.payslip_1}`
                                ))}>View payslip 1</span>
                            </td>
                            <td className="table-data">
                              <span
                                className={`view-texts ${!val?.payslip_2 ? 'view-texts-disabled' : ''}`}
                                onClick={!val?.payslip_2 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.payslip_2}`
                                ))}>View payslip 2</span>
                            </td>
                            <td className="table-data">
                              <span
                                className={`view-texts ${!val?.payslip_3 ? 'view-texts-disabled' : ''}`}
                                onClick={!val?.payslip_3 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.payslip_3}`
                                ))}>View payslip 3</span>
                            </td>
                            <td className='table-data'>
                              <span
                                className={`view-texts ${!val?.bank_statement_4 ? 'view-texts-disabled' : ''}`}
                                onClick={!val?.bank_statement_4 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.bank_statement_4}`))}>View bank statement</span>
                            </td>
                            <td className='table-data'>
                              <span
                                className={`view-texts ${!val?.bank_statement_5 ? 'view-texts-disabled' : ''}`}
                                onClick={!val?.bank_statement_5 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.bank_statement_5}`))}>View bank statement</span>
                            </td>
                            <td className='table-data'>
                              <span
                                className={`view-texts ${!val?.bank_statement_6 ? 'view-texts-disabled' : ''}`}
                                onClick={!val?.bank_statement_6 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.bank_statement_6}`))}>View bank statement</span>
                            </td>
                            <td className='table-data'>
                                <span
                                  className={`view-texts ${!val?.credit_card_statement_1 ? 'view-texts-disabled' : ''}`}
                                  onClick={!val?.credit_card_statement_1 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.credit_card_statement_1}`))}>View Credit Card statement</span>
                              </td>
                              <td className='table-data'>
                                <span
                                  className={`view-texts ${!val?.credit_card_statement_2 ? 'view-texts-disabled' : ''}`}
                                  onClick={!val?.credit_card_statement_2 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.credit_card_statement_2}`))}>View Credit Card statement</span>
                              </td>
                              <td className='table-data'>
                                <span
                                  className={`view-texts ${!val?.credit_card_statement_3 ? 'view-texts-disabled' : ''}`}
                                  onClick={!val?.credit_card_statement_3 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.credit_card_statement_3}`))}>View Credit Card statement</span>
                              </td>
                              <td className='table-data'>
                                <span
                                  className={`view-texts ${!val?.joint_account_statement_1 ? 'view-texts-disabled' : ''}`}
                                  onClick={!val?.joint_account_statement_1 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.joint_account_statement_1}`))}>View Joint acc statement</span>
                              </td>
                              <td className='table-data'>
                                <span
                                  className={`view-texts ${!val?.joint_account_statement_2 ? 'view-texts-disabled' : ''}`}
                                  onClick={!val?.joint_account_statement_2 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.joint_account_statement_2}`))}>View Joint acc statement</span>
                              </td>
                              <td className='table-data'>
                                <span
                                  className={`view-texts ${!val?.joint_account_statement_3 ? 'view-texts-disabled' : ''}`}
                                  onClick={!val?.joint_account_statement_3 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.joint_account_statement_3}`))}>View Joint acc statement</span>
                              </td>
                              <td className='table-data'>
                                <span
                                  className={`view-texts ${!val?.p60 ? 'view-texts-disabled' : ''}`}
                                  onClick={!val?.p60 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.p60}`))}>View P60</span>
                              </td>
                              <td className='table-data'>
                                <span
                                  className={`view-texts ${!val?.employment_contract ? 'view-texts-disabled' : ''}`}
                                  onClick={!val?.employment_contract ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.employment_contract}`))}>View Employment contract</span>
                              </td>
                              <td className='table-data'>
                                <span
                                  className={`view-texts ${!val?.tax_summary_1 ? 'view-texts-disabled' : ''}`}
                                  onClick={!val?.tax_summary_1 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.tax_summary_1}`))}>1st Tax Year Overview</span>
                              </td>
                              <td className='table-data'>
                                <span
                                  className={`view-texts ${!val?.tax_summary_2 ? 'view-texts-disabled' : ''}`}
                                  onClick={!val?.tax_summary_2 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.tax_summary_2}`))}>2nd Tax Year Overview</span>
                              </td>
                              <td className='table-data'>
                                <span
                                  className={`view-texts ${!val?.tax_summary_3 ? 'view-texts-disabled' : ''}`}
                                  onClick={!val?.tax_summary_3 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.tax_summary_3}`))}>3rd Tax Year Overview</span>
                              </td>
                              <td className='table-data'>
                                <span
                                  className={`view-texts ${!val?.tax_calculation_1 ? 'view-texts-disabled' : ''}`}
                                  onClick={!val?.tax_calculation_1 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.tax_calculation_1}`))}>1st Tax Year Calculation</span>
                              </td>
                              <td className='table-data'>
                                <span
                                  className={`view-texts ${!val?.tax_calculation_2 ? 'view-texts-disabled' : ''}`}
                                  onClick={!val?.tax_calculation_2 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.tax_calculation_2}`))}>2nd Tax Year Calculation</span>
                              </td>
                              <td className='table-data'>
                                <span
                                  className={`view-texts ${!val?.tax_calculation_3 ? 'view-texts-disabled' : ''}`}
                                  onClick={!val?.tax_calculation_3 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.tax_calculation_3}`))}>3rd Tax Year Calculation</span>
                              </td>
                              <td className='table-data'>
                                <span
                                  className={`view-texts ${!val?.company_account_1 ? 'view-texts-disabled' : ''}`}
                                  onClick={!val?.company_account_1 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.company_account_1}`))}>1st Company Account</span>
                              </td>
                              <td className='table-data'>
                                <span
                                  className={`view-texts ${!val?.company_account_2 ? 'view-texts-disabled' : ''}`}
                                  onClick={!val?.company_account_2 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.company_account_2}`))}>2nd Company Account</span>
                              </td>
                              <td className='table-data'>
                                <span
                                  className={`view-texts ${!val?.company_account_3 ? 'view-texts-disabled' : ''}`}
                                  onClick={!val?.company_account_3 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.company_account_3}`))}>3rd Company Account</span>
                              </td>
                            <td className="table-data" style={{
                              fontWeight: 'bold',
                              textTransform: 'capitalize',
                              color:
                                val?.approval_status === 'created' ? '#000000' :
                                  val?.approval_status === 'pending' ? '#ffc107' :
                                    val?.approval_status === 'approved' ? '#28a745' :
                                      val?.approval_status === 'rejected' ? 'red' :
                                        val?.approval_status === 'failed' ? 'red' :
                                          'black'
                            }}

                            >{val?.approval_status}</td>
                            <td className='table-data'>{val?.score != null ? `${val.score}%` : 'NA'}</td>
                            <td className="table-data">{val?.User?.username}</td>
                            <td className="table-data" style={{ minWidth: '100px', overflowX: 'auto' }}>
                              <span
                                className='text-btn'
                                onClick={() => navigate(`/case-detail?id=${val.id}`)}
                              >
                                Full Info
                              </span>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              )
            }
            {
              staffCasesHistoryData.length > 0 &&
              <ReactPaginate
                pageCount={paginationData.totalPages}
                pageRangeDisplayed={2}
                marginPagesDisplayed={3}
                forcePage={paginationData.pageNo - 1}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                activeClassName={'active'}
              />
            }
          </div>
        </div>
      </>

    )

  )
}
export default BrokerTeamCasesHistory